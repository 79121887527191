import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createClient } from '@supabase/supabase-js';
import { SessionContextProvider } from '@supabase/auth-helpers-react';
// import TagManager from 'react-gtm-module';
// import { HashRouter } form 'react-router-dom';

const supabase = createClient(
  "https://kqayqyagbhifabtgdoob.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImtxYXlxeWFnYmhpZmFidGdkb29iIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODMwODUzNTgsImV4cCI6MTk5ODY2MTM1OH0.icKJ6rDK42-P1FEnGB-dMIVt_pORR2qlD6baoNRk3lQ" 
);
// TagManager.initialize({ gtmId: 'GTM-T4MBS98B' });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <SessionContextProvider supabaseClient={supabase}>
      <App />
    </SessionContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
